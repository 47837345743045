import React from 'react';

const Visit5TechLab = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-900 to-purple-900 font-['Roboto_Mono',_monospace] p-4">
      <style>{`
        @keyframes pulse {
          0%, 100% { transform: scale(1); }
          50% { transform: scale(1.1); }
        }
        @keyframes spin {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }
        @keyframes wave {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }
        .pulse { animation: pulse 3s infinite; }
        .spin { animation: spin 10s linear infinite; }
        .wave { animation: wave 2s ease-in-out infinite; }
      `}</style>
      <div className="flex flex-wrap justify-center items-center mb-8 gap-8">
        {/* AI Brain Network */}
        <svg className="w-20 h-20 text-blue-300 pulse" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="45" stroke="currentColor" strokeWidth="2"/>
          <path d="M30 70 L50 30 L70 70 Z" stroke="currentColor" strokeWidth="2"/>
          <circle cx="50" cy="30" r="5" fill="currentColor"/>
          <circle cx="30" cy="70" r="5" fill="currentColor"/>
          <circle cx="70" cy="70" r="5" fill="currentColor"/>
        </svg>
        
        {/* Database */}
        <svg className="w-20 h-20 text-green-300 spin" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 25 Q50 0 80 25 Q50 50 20 25 Z" stroke="currentColor" strokeWidth="2"/>
          <path d="M20 50 Q50 25 80 50 Q50 75 20 50 Z" stroke="currentColor" strokeWidth="2"/>
          <path d="M20 75 Q50 50 80 75 Q50 100 20 75 Z" stroke="currentColor" strokeWidth="2"/>
        </svg>
        
        {/* ML Graph */}
        <svg className="w-20 h-20 text-yellow-300 wave" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 90 L90 90" stroke="currentColor" strokeWidth="2"/>
          <path d="M10 10 L10 90" stroke="currentColor" strokeWidth="2"/>
          <path d="M10 70 Q30 30 50 50 T90 30" stroke="currentColor" strokeWidth="2"/>
        </svg>
      </div>
      <a
        href="https://5techlab.com/"
        rel="noopener noreferrer"
        className="text-4xl md:text-6xl font-bold text-white hover:text-yellow-300 transition-colors duration-300 transform hover:scale-105 text-center"
      >
        Visit 5 Tech Lab
      </a>
    </div>
  );
};

export default Visit5TechLab;