import React from 'react';
import Visit5TechLab from './Visit5TechLab';

function App() {
  return (
    <div className="App">
      <Visit5TechLab />
    </div>
  );
}

export default App;